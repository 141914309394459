import isEmpty from 'lodash/isEmpty';
import moment from 'moment-business-days';

import { sessionUser } from 'utility/sessionStorageHelper';
const momentTimezone = require('moment-timezone');

export const fillReportCriteriaValues = (section, values) => {
  return section
    .filter(({ id }) => !isEmpty(values[id]))
    .filter(item => {
      if (item.type === 'dropdown') {
        return [item.defaultOptionLabel].includes(values[item.id].id) ? false : true;
      }
      return true;
    })
    .map(field => {
      if (!isEmpty(values[field.id])) {
        if (field.type === 'list') {
          const selectAllValue = field.source.length === values[field.id].length;
          return {
            value: { values: values[field.id].map(item => item.id), selectAll: selectAllValue },
            key: field.id
          };
        }
        return { value: field.type === 'dropdown' ? values[field.id].id : values[field.id], key: field.id };
      }
    });
};

/**
 * The Report Filter section has three different date fields depending on the contents of the report template
 * (Value Date, Process Date, and Relative Process Date). This method adds the correct date field's value to
 * the payload when saving the custom report.
 */
export const fillSettingsValues = (section, values) => {
  const payload = {
    repeatPaymentData: values['repeatPaymentData']?.id
  };

  const hasReportScheduleData = !!values?.repeatSchedule;

  if (hasReportScheduleData) {
    //If the user selected schedule data from the Schedule section, then we want the Relative Process Date dropdown field.
    payload['relativeProcessDate'] = values['relativeProcessDate']?.id;
  } else {
    //Check if Process Date or Value Date radio button was selected, and set dateField to the corresponding date range field.
    const dateField = section
      .find(item => item.type === 'daterange')
      .field.find(item => item.id === values['generalSettings']);

    if (!isEmpty(values[dateField.id])) {
      payload[[dateField.id]] = values[dateField.id];
      payload[[dateField.id]].currentDay = false;
      payload[[dateField.id]].previousDay = false;
      payload[[dateField.id]].previousWeek = false;
      payload[[dateField.id]].previousMonth = false;

      const currentDate = moment().isBusinessDay()
        ? moment().format('YYYY-MM-DD')
        : moment()
            .nextBusinessDay()
            .format('YYYY-MM-DD');

      const prevDate = moment()
        .prevBusinessDay()
        .format('YYYY-MM-DD');

      const prevWeek = moment()
        .subtract(1, 'week')
        .format('YYYY-MM-DD');

      const prevMonth = moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD');

      if (values[dateField.id].from === values[dateField.id].to) {
        if (currentDate === values[dateField.id].to) {
          payload[[dateField.id]].currentDay = true;
        } else if (prevDate === values[dateField.id].to) {
          payload[[dateField.id]].previousDay = true;
        }
      } else {
        if (prevWeek === values[dateField.id].from && currentDate === values[dateField.id].to) {
          payload[[dateField.id]].previousWeek = true;
        } else if (prevMonth === values[dateField.id].from && currentDate === values[dateField.id].to) {
          payload[[dateField.id]].previousMonth = true;
        }
      }
    }
  }

  return payload;
};

/**
 * Creates a unix cronJob from the given form values.
 * Refer to https://www.quartz-scheduler.net/documentation/quartz-2.x/tutorial/crontriggers.html?secureweb=Teams
 *
 * @param values.runTime - time in mm:hh format in 24 hour time (ex. "16:30").
 * @param values.startDate - a datestring describing the start date for the cronjob. Can be in any format compatible with moment().
 * @returns a list of cronJob strings describing how often the custom report should run (multiple cronjobs may be required for a single report)
 */
export const generateCronJobFromReportSchedule = values => {
  let cronJobs = [];

  //Check to make sure required fields are present
  if (isEmpty(values) || isEmpty(values.runTime) || isEmpty(values.startDate) || values.repeatSchedule < 0) {
    return undefined;
  }

  const { time: runTime, startDate, endDate } = convertScheduleFromUserToDatabaseTimezone(
    values.runTime,
    values.startDate,
    values.endDate
  );

  if (isEmpty(runTime) || isEmpty(startDate) || isEmpty(endDate)) {
    return undefined;
  }

  let [hour, minute] = runTime.split(':');

  if (isEmpty(hour) || isEmpty(minute)) {
    return undefined;
  }

  //Remove any leading zeros from hour and minute values - trying to do minute.charAt(0) === '0' crashes the function for some reason...
  const firstMinuteChar = minute.charAt(0);
  if (minute && firstMinuteChar === '0') {
    minute = minute.substring(1);
  }
  const firstHourChar = hour.charAt(0);
  if (hour && firstHourChar == '0') {
    hour = hour.substring(1);
  }

  let dayOfMonth = '*';
  let month = '*';
  let dayOfWeek = '?';

  if (values.repeatSchedule == 0) {
    //Every day
    dayOfMonth = '*';
    month = '*';
    dayOfWeek = '?';
  } else if (values.repeatSchedule == 1) {
    //Every week, on the same day of the week as the start date
    dayOfMonth = '?';
    month = '*';
    //Moment.js uses 0-indexed days of the week (0 = Sunday), but TFC expects 1-indexed (1 = Sunday)
    dayOfWeek = moment(startDate).day() + 1;
  } else if (values.repeatSchedule == 2) {
    //Every month - default to running on the first day of each month
    const [year, m, day] = startDate.split('-');
    dayOfMonth = Number(day);
    if (dayOfMonth > 28) {
      cronJobs.push('0 0 0 L 2 ?');
    }
    month = '*';
    dayOfWeek = '?';
  } else if (values.repeatSchedule == 3) {
    //Every quarter - runs on the first day of each quarter
    dayOfMonth = '1';
    month = 'JAN,APR,JUL,OCT';
    dayOfWeek = '?';
  }

  const cronJob = [0, minute, hour, dayOfMonth, month, dayOfWeek].join(' ');
  cronJobs.push(cronJob);

  return { reportScheduleCronJobs: cronJobs, runTime: runTime, startDate: startDate, endDate: endDate };
};

const getDatabaseTimezone = environment => {
  switch (environment.toUpperCase()) {
    case 'DEV':
      return 'UTC';
    case 'QA':
      return 'EST';
    default:
      return 'CST';
  }
};

const getTimeZoneName = abbreviation => {
  const allTimeZones = momentTimezone.tz.names();

  for (const zone of allTimeZones) {
    if (momentTimezone.tz(zone).format('z') === abbreviation) {
      return zone;
    }
  }

  return null; // Abbreviation not found
};

/**
 * Converts a given moment.js object to the given time zone, and returns the time and date portions separately.
 *
 * @param {Moment} date - A moment.js object with the original date and time to be converted
 * @param {string} resultTimezone - A string with a moment-timezone compatible timezone name that the inputted date will be converted into
 * @returns an object containing two fields:
 *  * time - a string with the converted time in HH:mm format
 *  * date - a string with the converted time in YYYY-MM-DD format
 */
const convertDateAndTimeToTimezone = (date, resultTimezone) => {
  if (!date || !resultTimezone) {
    return { time: null, date: null };
  }

  const resultTime = date
    .clone()
    .tz(resultTimezone)
    .format('HH:mm');
  const resultDate = date
    .clone()
    .tz(resultTimezone)
    .format('YYYY-MM-DD');

  return { time: resultTime, date: resultDate };
};

export const convertScheduleFromUserToDatabaseTimezone = (runTime, startDate, endDate) => {
  const defaultResult = {
    time: null,
    startDate: null,
    endDate: null
  };

  //Get user's selected timezone
  const user = sessionUser.getLoggedInUser();
  const userTimeZone = user?.userTimezone?.abbrevatedName;
  const timeZoneName = getTimeZoneName(userTimeZone);

  if (!timeZoneName) {
    return defaultResult;
  }

  //Create moment objects for the start and end dates in the user's timezone, using runTime as their time values
  const combinedStartDate = momentTimezone.tz(`${startDate} ${runTime}`, timeZoneName);
  const combinedEndDate = momentTimezone.tz(`${endDate} ${runTime}`, timeZoneName);

  //Get the DB timezone corresponding to the current environment
  const dbTimezone = getDatabaseTimezone(window.BUILD_ENVIRONMENT);
  const dbZoneName = getTimeZoneName(dbTimezone);

  if (!dbZoneName) {
    return defaultResult;
  }

  //Convert the report run time to the DB's timezone, which may require adjusting the start and end dates if the time zone difference crosses into a different day
  const { time: resultRunTime, date: resultStartDate } = convertDateAndTimeToTimezone(combinedStartDate, dbZoneName);
  const { date: resultEndDate } = convertDateAndTimeToTimezone(combinedEndDate, dbZoneName);

  return {
    time: resultRunTime,
    startDate: resultStartDate,
    endDate: resultEndDate
  };
};

export const convertScheduleFromDatabaseToUserTimezone = (runTime, startDate, endDate) => {
  const defaultResult = {
    time: null,
    startDate: null,
    endDate: null
  };

  //Get the DB timezone corresponding to the current environment
  const dbTimezone = getDatabaseTimezone(window.BUILD_ENVIRONMENT);
  const dbZoneName = getTimeZoneName(dbTimezone);

  if (!dbZoneName) {
    return defaultResult;
  }

  //Create moment objects for the start and end dates in the DB's timezone, using runTime as their time values
  const combinedStartDate = momentTimezone.tz(`${startDate} ${runTime}`, dbZoneName);
  const combinedEndDate = momentTimezone.tz(`${endDate} ${runTime}`, dbZoneName);

  //Get user's selected timezone
  const user = sessionUser.getLoggedInUser();
  const userTimeZone = user?.userTimezone?.abbrevatedName;
  const timeZoneName = getTimeZoneName(userTimeZone);

  if (!timeZoneName) {
    return defaultResult;
  }

  //Convert the report run time to the user's timezone, which may require adjusting the start and end dates if the time zone difference crosses into a different day
  const { time: resultRunTime, date: resultStartDate } = convertDateAndTimeToTimezone(combinedStartDate, timeZoneName);
  const { date: resultEndDate } = convertDateAndTimeToTimezone(combinedEndDate, timeZoneName);

  return {
    time: resultRunTime,
    startDate: resultStartDate,
    endDate: resultEndDate
  };
};
