import { makeStyles } from '@jpmuitk/theme';
import cssVariables from 'Constants/_variables.module.scss';

export const IMAGEVIEWER_HEIGHT = 400;
/* Link */
export const noFocus = {
  '&:focus': {
    outline: 'none !important'
  }
};

export const numericColumnStyles = { textAlign: 'right' };

export const announcementOffScreen = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  width: '1px',
  position: 'absolute'
};

export const cellHeightFix = {
  wordBreak: 'break-all',
  whiteSpace: 'normal',
  height: 'auto !important'
};
export const gridCellSwitchStyles = {
  root: { justifyContent: 'flex-end' },
  focusVisible: {
    '&:before': {
      outline: 'none !important'
    }
  }
};

export const gridCellButtonStyles = {
  root: {
    '&:focus': {
      outline: 'none !important'
    }
  }
};

export const appWrapper = {
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  }
};

export const radioGroup = {
  root: {
    flexDirection: 'row',
    marginBottom: cssVariables.space3,
    '& div': {
      width: '200px'
    }
  }
};

export const formPadding = {
  height: 277
};

export const referencePadding = {
  height: 75
};

export const heightReset = {
  height: 'initial !important'
};

const labelStyles = {
  fontSize: '11px',
  lineHeight: 1
};

export const error = {
  ...labelStyles
};

const label = {
  ...labelStyles,
  color: cssVariables.grey200
};

export const inputStyles = {
  label: {
    ...label,
    paddingLeft: '4px'
  }
};

export const noButtonWithLabelStyles = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  outline: 'inherit'
};

export const noButtonStyles = {
  ...noButtonWithLabelStyles,
  textAlign: 'left',
  color: 'inherit',
  padding: 0,
  font: 'inherit'
};
/* Technical debt -- Needed due to IE11 crash */
export const checkboxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  labelPlacementTop: {
    display: 'inline-flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    '& label': {
      paddingLeft: '5px',
      marginBottom: '6px'
    },
    '& input:disabled': {
      cursor: 'not-allowed'
    },
    '& input:disabled + label': {
      cursor: 'default'
    }
  },
  input: {
    cursor: 'pointer',
    '&:focus': {
      outline: '2px dotted #2D81BD !important'
    },
    '&:hover': {
      outline: '5px auto -webkit-focus-ring-color'
    }
  },
  label: {
    ...label,
    paddingLeft: '4px',
    width: 'inherit',
    display: 'inline-block',
    cursor: 'pointer'
  }
};

export const list = {
  listStyle: 'none',
  margin: 0,
  padding: 0
};

export const legend = {
  color: cssVariables.grey200,
  fontSize: 11,
  paddingBottom: 1
};

const errorStyles = {
  color: cssVariables.red500
};

export const errorMsg = {
  ...errorStyles,
  fontSize: '11px',
  lineHeight: '1.5'
};

export const listStyles = {
  list,
  densityLow: {
    marginBottom: cssVariables.space3
  }
};

export const fieldSetStyles = {
  root: legend
};

export const pageStyles = {
  root: {
    display: 'flex',
    flexGrow: 1
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  pageWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',

    '& > *': {
      padding: `0 ${cssVariables.space1}`,

      '&:not(:first-child):not(:last-child)': {
        marginTop: 0,
        marginBottom: cssVariables.space1
      },
      '&:first-child': {
        marginTop: cssVariables.space1,
        marginBottom: cssVariables.space1
      }
    }
  },
  centerContentWrapper: {
    width: 'calc(100% / 1.2)',
    maxWidth: '1080px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    flexDirection: 'column',
    overflow: 'auto',
    '& .ag-theme-material': {
      display: 'flex',
      flexGrow: 1,
      overflowX: 'auto',
      '& > div': {
        flexGrow: 1,
        ...heightReset
      }
    },
    '& .link': {
      marginBottom: cssVariables.space3
    }
  },
  contentWrapper: {
    position: 'relative',
    // All children require div wrapper
    // first-child and last-child have unique padding
    '& > div': {
      '&:not(:first-child):not(:last-child)': {
        marginTop: `${cssVariables.space1}`,
        marginBottom: `${cssVariables.space1}`
      },
      '&:not(:only-child):first-child': {
        marginBottom: cssVariables.space1
      },
      '&:not(:only-child):last-child': {
        marginTop: cssVariables.space1
      }
    }
  },
  noFooter: {
    paddingBottom: `${cssVariables.space1} !important`
  },
  footer: {
    marginBottom: cssVariables.space2
  }
};

export const usePageStyles = makeStyles(theme => {
  return {
    ...pageStyles,
    content: ({ narrow, justify }) => ({
      position: narrow ? 'relative' : undefined,
      overflow: narrow ? undefined : 'auto',
      flexGrow: 1,
      flexBasis: 0,
      display: 'flex',
      justifyContent: justify ? 'center' : undefined,
      flexDirection: 'column',
      '& .ag-theme-material': {
        display: 'flex',
        flexGrow: 1,
        overflowX: 'auto',
        '& > div': {
          flexGrow: 1,
          ...heightReset
        }
      },
      '& .link': {
        marginBottom: cssVariables.space3
      }
    })
  };
});

/**
 * Panels
 */

export const sdeExpansionPanelStyles = () => ({
  root: {
    margin: '24px 0',
    padding: 0
  }
});

export const expansionPanelStyles = () => ({
  panelDetailsRoot: {
    flexDirection: 'column',
    '& > div': {
      marginTop: 24
    },
    // Assumption is first child will be form-fields which includes 12px padding
    '& > div:first-child': {
      marginTop: 12
    }
  }
});

// This panel has no left or right spacing, useful for spacing content
export const fieldPanel = () => ({
  root: {
    width: 'calc(100% + 24px)',
    margin: '24px -12px',
    padding: 0
  }
});
