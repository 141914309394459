import i18n from 'i18n';

import {
  LOAD_CUSTOM_REPORTS_TEMPLATE_FAILURE,
  LOAD_CUSTOM_REPORTS_TEMPLATE_REQUEST,
  LOAD_CUSTOM_REPORTS_TEMPLATE_SUCCESS,
  LOAD_CUSTOM_REPORTS_SETTINGS_FAILURE,
  LOAD_CUSTOM_REPORTS_SETTINGS_REQUEST,
  LOAD_CUSTOM_REPORTS_SETTINGS_SUCCESS,
  RUN_CUSTOM_REPORT_REQUEST,
  RUN_CUSTOM_REPORT_SUCCESS,
  RUN_CUSTOM_REPORT_FAILURE,
  SAVE_CUSTOM_REPORT_REQUEST,
  SAVE_CUSTOM_REPORT_SUCCESS,
  SAVE_CUSTOM_REPORT_FAILURE,
  CLEAR_RUN_ERROR
} from './customReportActions';

import isEmpty from 'lodash/isEmpty';
import { convertScheduleFromDatabaseToUserTimezone } from '../utility/createReportUtils';

const initialState = {
  customReportTemplateError: {},
  runCustomReportError: {},
  isFetchingTemplateSettings: false,
  reportTemplateSettings: {},
  settingsError: {},
  isSaving: false,
  saveCustomReportError: {},
  reportTemplates: []
};

const clearRunError = state => {
  return {
    ...state,
    runCustomReportError: {}
  };
};

const loadCustomReportsRequest = state => {
  return {
    ...state,
    isFetchingTemplate: true,
    reportTemplates: [],
    customReportTemplateError: {}
  };
};

const loadCustomReportsSuccess = (state, action) => {
  return {
    ...state,
    isFetchingTemplate: false,
    reportTemplates: action.response.data.userTemplates,
    customReportTemplateError: {}
  };
};

const loadCustomReportsFailure = (state, action) => {
  return {
    ...state,
    isFetchingTemplate: false,
    reportTemplates: [],
    customReportTemplateError: action.errors
  };
};

const loadReportSettingsRequest = state => {
  return {
    ...state,
    isFetchingTemplateSettings: true,
    reportTemplateSettings: {},
    settingsError: {}
  };
};

const loadReportSettingsSuccess = (state, action) => {
  const reportScheduleSettings = action.response.data.reportScheduleSettings;

  const runTime = reportScheduleSettings?.runTime?.value;
  const startDate = reportScheduleSettings?.startDate?.value;
  const endDate = reportScheduleSettings?.endDate?.value;

  //If the report has schedule settings, we need to convert the time and date info from the DB's timezone to the user's selected timezone
  if (!isEmpty(runTime) && !isEmpty(startDate) && !isEmpty(endDate)) {
    const {
      time: convertedRunTime,
      startDate: convertedStartDate,
      endDate: convertedEndDate
    } = convertScheduleFromDatabaseToUserTimezone(runTime, startDate, endDate);

    reportScheduleSettings.runTime.value = convertedRunTime;
    reportScheduleSettings.startDate.value = convertedStartDate;
    reportScheduleSettings.endDate.value = convertedEndDate;

    action.response.data.reportScheduleSettings = reportScheduleSettings;
  }

  return {
    ...state,
    isFetchingTemplateSettings: false,
    reportTemplateSettings: action.response.data,
    settingsError: {}
  };
};

const loadReportSettingsFailure = (state, action) => {
  return {
    ...state,
    isFetchingTemplateSettings: false,
    reportTemplateSettings: {},
    settingsError: action.errors
  };
};
const runCustomReportsRequest = state => {
  return {
    ...state,
    isFetching: true,
    runCustomReportError: {}
  };
};

const runCustomReportsSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    runCustomReportError: {},
    reportTemplates: [],
    customReportTemplateError: action.errors
  };
};

const runCustomReportsFailure = (state, action) => {
  return {
    ...state,
    isFetching: false,
    runCustomReportError: action.errors
  };
};
const saveCustomReportsRequest = state => {
  return {
    ...state,
    isSaving: true,
    saveCustomReportError: {}
  };
};

const saveCustomReportsSuccess = (state, action) => {
  return {
    ...state,
    isSaving: false,
    responseTemplateId: action.response.data.templateId || action.response.data.requestId,
    saveCustomReportError: {},
    customReportTemplateError: action.errors
  };
};

const saveCustomReportsFailure = (state, action) => {
  return {
    ...state,
    isSaving: false,
    saveCustomReportError: action.error
  };
};

export default function customReportReducer(state = initialState, action) {
  const handlers = {
    [LOAD_CUSTOM_REPORTS_TEMPLATE_REQUEST]: loadCustomReportsRequest,
    [LOAD_CUSTOM_REPORTS_TEMPLATE_SUCCESS]: loadCustomReportsSuccess,
    [LOAD_CUSTOM_REPORTS_TEMPLATE_FAILURE]: loadCustomReportsFailure,
    [LOAD_CUSTOM_REPORTS_SETTINGS_REQUEST]: loadReportSettingsRequest,
    [LOAD_CUSTOM_REPORTS_SETTINGS_SUCCESS]: loadReportSettingsSuccess,
    [LOAD_CUSTOM_REPORTS_SETTINGS_FAILURE]: loadReportSettingsFailure,
    [RUN_CUSTOM_REPORT_REQUEST]: runCustomReportsRequest,
    [RUN_CUSTOM_REPORT_SUCCESS]: runCustomReportsSuccess,
    [RUN_CUSTOM_REPORT_FAILURE]: runCustomReportsFailure,
    [SAVE_CUSTOM_REPORT_REQUEST]: saveCustomReportsRequest,
    [SAVE_CUSTOM_REPORT_SUCCESS]: saveCustomReportsSuccess,
    [SAVE_CUSTOM_REPORT_FAILURE]: saveCustomReportsFailure,
    [CLEAR_RUN_ERROR]: clearRunError
  };

  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
