import { dateComparator } from 'utility/comparators';

function stopGoColumnDefs(t, userSettings) {
  return [
    {
      field: 'uploadDate',
      headerName: t('stopGo.headerType.uploadDate'),
      comparator: dateComparator(userSettings),
      resizable: true,
      cellClass: 'uploadDate-cls'
    },
    {
      field: 'originalFileName',
      headerName: t('stopGo.headerType.originalFileName'),
      resizable: true,
      cellClass: 'originalFileName-cls'
    },
    {
      field: 'program',
      headerName: t('stopGo.headerType.program'),
      resizable: true,
      cellClass: 'program-cls'
      // cellRenderer: 'emptyCellRenderer'
    },
    {
      field: 'actionType',
      headerName: t('stopGo.headerType.actionType'),
      resizable: true,
      cellClass: 'actionType-cls'
      // cellRenderer: 'emptyCellRenderer'
    },
    {
      field: 'effectiveDate',
      headerName: t('stopGo.headerType.effectiveDate'),
      comparator: dateComparator(userSettings),
      resizable: true,
      cellClass: 'effectiveDate-cls'
    },
    {
      field: 'fileStatus',
      headerName: t('stopGo.headerType.fileStatus'),
      resizable: true,
      cellClass: 'fileStatus-cls'
    },
    {
      field: 'errorMessage',
      headerName: t('stopGo.headerType.errorMessage'),
      resizable: true,
      cellClass: 'errorMessage-cls'
    }
  ];
}

export default stopGoColumnDefs;
