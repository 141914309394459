import { sessionUser } from 'utility/sessionStorageHelper';

export const payerEntityURL = endpoint => {
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return `payersvc/v0/cust/${customerId}/user/${userId}/entity/${endpoint}`;
};

export const payerEntityWorkflowURL = endpoint => {
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return `payersvc/v0/cust/${customerId}/user/${userId}/workflow/${endpoint}`;
};

export const customerClassificationURL = endpoint => {
  const { customerId, userId } = sessionUser.getLoggedInUser().getId();
  return `payersvc/v0/cust/${customerId}/user/${userId}/${endpoint}`;
};

export const usersURL = endpoint => {
  const { customerId } = sessionUser.getLoggedInUser().getId();
  return `usersvc/v0/users/cust/${customerId}/${endpoint}`;
};

export const getGpxUrl = endpoint => {
  const {
    portalSsoName,
    userCustomer: { custWebId }
  } = sessionUser.getLoggedInUser();

  return `${window.GPX_URL + endpoint}?userId=${portalSsoName}&custWebId=${custWebId}`;
};
