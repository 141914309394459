import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import _startsWith from 'lodash/startsWith';
import isObject from 'lodash/isObject';
import noop from 'lodash/noop';
import isFinite from 'lodash/isFinite';
import {
  normalizeDelimiters,
  stripDelimiters,
  getDelimiters,
  amountValidations,
  delimiterToEnglish
} from 'hooks/useFieldFormatter/amountFormatter';

import { getIn } from 'final-form';
import moment from 'moment-business-days';
import {
  ALPHA_AND_SPACE_ONLY_REGEX,
  ALPHA_NUMERIC,
  ALPHA_NUMERIC_AND_SPACE,
  ALPHA_AND_SPECIAL_CHARACTERS,
  NUMERIC_AND_SPECIAL_CHARACTERS,
  ALPHA_NUMERIC_AND_SPECIAL_CHARACTERS
} from 'Constants/regex';
import i18n from 'i18n';
import { DATE_RANGE, RANGE } from 'config/forms/fields';

const base = 10;

const getRangeNameAndId = fieldWithRangeName => [
  fieldWithRangeName
    .split('.')
    .slice(0, -1)
    .join('.'),
  fieldWithRangeName.split('.').pop()
];

export const extraSpace = () => (value, allValues, { name }) => {
  const [rangeName, rangeId] = getRangeNameAndId(name);
  if (value && (typeof value === 'string' || typeof value === 'number') && value.toString().match(/^ *$/) !== null) {
    const fieldName =
      rangeId === RANGE(i18n.t).TO.id
        ? RANGE(i18n.t).TO.label
        : rangeId === RANGE(i18n.t).FROM.id
        ? RANGE(i18n.t).FROM.label
        : i18n.t('validation.amount');
    return { type: 'error', msg: i18n.t('validation.extraSpace', { field: fieldName }) };
  }
  return undefined;
};

export const processError = ({ error, submitError }) => ({ createError, createWarning }) => {
  const validationOrSubmitError = error || submitError;
  const { type, msg } = validationOrSubmitError;
  return type === 'error' ? createError(msg) : type === 'warning' ? createWarning(msg) : undefined;
};

export const composeValidators = (validators = [], asyncValidator = noop) => (value, values, meta) =>
  validators.reduce((error, validator) => {
    return error || (validator && validator(value, values, meta));
  }, undefined) ||
  (value && asyncValidator(value, values));

export const isValidRoutingNumber = value =>
  value && !/^(?!0+$)[0-9]{8,9}$/.test(value)
    ? { type: 'error', msg: i18n.t('validation.isValidRoutingNumber') }
    : undefined;

export const mustBeFalse = msg => value => (value ? { type: 'error', msg } : undefined);

export const isRequiredRange = (required, msg) => (value = '', allValues, { name } = {}) => {
  const [rangeName, rangeId] = getRangeNameAndId(name);
  const fields = rangeName.split('.');
  if (!getIn(allValues, rangeName)) {
    return undefined;
  }
  const requiredOverride =
    allValues[name] ||
    getIn(allValues, `${rangeName}.${rangeId === RANGE(i18n.t).FROM.id ? RANGE(i18n.t).TO.id : RANGE(i18n.t).FROM.id}`);
  const fieldName =
    rangeId === RANGE(i18n.t).TO.id
      ? RANGE(i18n.t).TO.label
      : rangeId === RANGE(i18n.t).FROM.id
      ? RANGE(i18n.t).FROM.label
      : i18n.t('validation.amount');
  return (requiredOverride || required) && !String(value).trim().length
    ? { type: 'error', msg: msg || i18n.t('validation.required', { field: fieldName }) }
    : undefined;
};

export const isRangeValid = ({ msg, formatter, unformatter, label }) => (value, allValues, { name }) => {
  const [rangeName] = getRangeNameAndId(name);
  const toValue =
    allValues[`${rangeName}.${RANGE(i18n.t).TO.id}`] || getIn(allValues, `${rangeName}.${RANGE(i18n.t).TO.id}`);
  // Legacy || Final-Form
  const to =
    toValue !== ''
      ? Number(unformatter ? unformatter(toValue) : toValue ? stripDelimiters(toValue) : toValue)
      : undefined;
  const fromValue =
    allValues[`${rangeName}.${RANGE(i18n.t).FROM.id}`] || getIn(allValues, `${rangeName}.${RANGE(i18n.t).FROM.id}`);
  const from =
    fromValue !== ''
      ? Number(unformatter ? unformatter(fromValue) : fromValue ? stripDelimiters(fromValue) : fromValue)
      : undefined;

  return !isNaN(from) && !isNaN(to) && to < from
    ? {
        type: 'error',
        msg:
          msg ||
          i18n.t('validation.invalidRange', {
            rangeLabel: label,
            fromLabel: RANGE(i18n.t).FROM.label,
            toLabel: RANGE(i18n.t).TO.label,
            min: formatter ? formatter(from)[0] : from,
            max: formatter ? formatter(to)[0] : to
          })
      }
    : undefined;
};

export const isRequired = (required, msg, label) => (value = '') => {
  return !required || ((!isEmpty(value) || isFinite(value)) && String(value).trim().length)
    ? undefined
    : {
        type: 'error',
        msg: label ? i18n.t('validation.isRequired', { field: label }) : msg || i18n.t('validation.required')
      };
};

export const isValidWithRegex = (msg, regexString) => value => {
  if (isEmpty(regexString)) {
    return undefined;
  }
  const regex = new RegExp(regexString);
  return !isEmpty(regexString) && value && !regex.test(value) ? { type: 'error', msg } : undefined;
};

export const isNumeric = value =>
  !isNil(value) && isNaN(value) ? { type: 'error', msg: i18n.t('validation.isNumeric') } : undefined;

export const isNumber = value =>
  !isNil(value) && isNaN(value) ? { type: 'error', msg: i18n.t('validation.isNumber') } : undefined;

export const isInvalidCharacter = ({ userCurrencyFormat } = {}, allowSign) => (value, values, { name }) => {
  const [thousandDelimiter, radixDecimalDelimiter] = getDelimiters(userCurrencyFormat);
  const [rangeName, rangeId] = getRangeNameAndId(name);
  const sign = allowSign ? '-?' : '';
  const regex = new RegExp(
    `(?=.*?\\d)^\\$?[-+]?(([1-9]\\d{0,2}(${thousandDelimiter}\\d{3})*)|\\d+)?(\\${radixDecimalDelimiter}\\d{1,2})?$`
  );
  const fieldName =
    rangeId === RANGE(i18n.t).TO.id
      ? RANGE(i18n.t).TO.label
      : rangeId === RANGE(i18n.t).FROM.id
      ? RANGE(i18n.t).FROM.label
      : i18n.t('validation.amount');

  const msg = i18n.t('validation.invalidCharacters', {
    field: fieldName,
    str1: delimiterToEnglish(i18n.t)[thousandDelimiter],
    str2: delimiterToEnglish(i18n.t)[radixDecimalDelimiter]
  });

  return !isNil(value) && isNaN(value) && !regex.test(value) ? { type: 'error', msg } : undefined;
};

export const isNumberInCurrencyFormat = settings => value => {
  const val = value ? value.replace('-', '') : value;
  const regex = amountValidations(settings).regex;
  const isValidNumber = isNaN(Number(val)) ? regex.test(val) : true;
  const [thousandDelimiter, radixDecimalDelimeter] = getDelimiters(settings);
  const [_, cents] = (value || '').split(radixDecimalDelimeter) || [];
  const isCentsValid = (cents?.length || 0) <= 2;

  return (!isCentsValid || !isValidNumber) && !isEmpty(val) && val !== '0'
    ? {
        type: 'error',
        msg: i18n.t('validation.mustBeNumericInFormat', { str: settings.userCurrencyFormat.description })
      }
    : undefined;
};

export const isInteger = value =>
  !isNil(value) && (!/^-?\d+$/.test(value) ? { type: 'error', msg: i18n.t('validation.isInteger') } : undefined);

export const isAlpha = value =>
  !isNil(value) && !ALPHA_AND_SPACE_ONLY_REGEX.test(value)
    ? { type: 'error', msg: i18n.t('validation.alphaOnly') }
    : undefined;

export const isAlphaNumeric = value =>
  !isNil(value) && !ALPHA_NUMERIC.test(value)
    ? { type: 'error', msg: i18n.t('validation.alphanumericOnly') }
    : undefined;

export const isAlphaNumericWithSpace = value =>
  !isNil(value) && !ALPHA_NUMERIC_AND_SPACE.test(value)
    ? { type: 'error', msg: i18n.t('validation.alphanumericWithSpaceOnly') }
    : undefined;

export const isAlphaWithSpecialChar = name => value => {
  if (isNil(value) || isEmpty(value)) {
    return undefined;
  }
  return !ALPHA_AND_SPECIAL_CHARACTERS.test(value)
    ? { type: 'error', msg: i18n.t('validation.mustBeAlpha', { field: name }) }
    : undefined;
};

export const isNumberWithSpecialChar = value => {
  if (isNil(value) || isEmpty(value)) {
    return undefined;
  }

  return !NUMERIC_AND_SPECIAL_CHARACTERS.test(value)
    ? { type: 'error', msg: i18n.t('validation.isNumber') }
    : undefined;
};

export const isCurrency = value =>
  value && !/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(value)
    ? { type: 'error', msg: i18n.t('validation.isCurrency') }
    : undefined;

export const containsSpecialChars = value =>
  value && /[@_!#$%^&*()<>?/|}{~:]/.test(value)
    ? { type: 'error', msg: i18n.t('validation.containsSpecialChars') }
    : undefined;

export const isValidRange = (
  tempMax,
  greaterThanMinimumMessage,
  lessThanMaximumMessage,
  minimumLessThanMaximumMessage,
  min = 0,
  max = tempMax + 1
) => value => {
  const [minValue, maxValue] = value?.includes('-') ? value.split('-') : [value];
  return (
    isNumeric(minValue) ||
    isNumeric(maxValue) ||
    isGreaterThan(min, greaterThanMinimumMessage())(minValue) ||
    isLessThan(max, lessThanMaximumMessage(tempMax))(minValue) ||
    isGreaterThan(min, greaterThanMinimumMessage())(maxValue) ||
    isLessThan(max, lessThanMaximumMessage(tempMax))(maxValue) ||
    (Number(minValue) > Number(maxValue) && {
      type: 'error',
      msg: minimumLessThanMaximumMessage()
    })
  );
};

// minValue !== '' - Final-From defaults empty inputs to undefined while browsers to empty strings
// This check forces the browsers to behave like Final-Form
// stripDelimiters - Necessary in order to process currency input in non-currency ranges
export const isGreaterThan = (minValue, msg, unformatter) => maxValue => {
  const max =
    maxValue !== ''
      ? Number(unformatter ? unformatter(maxValue) : maxValue ? stripDelimiters(maxValue) : maxValue)
      : undefined;
  const min =
    minValue !== ''
      ? Number(unformatter ? unformatter(minValue) : minValue ? stripDelimiters(minValue) : minValue)
      : undefined;

  return !isNaN(min) && max <= min
    ? { type: 'error', msg: msg || i18n.t('validation.isGreaterThan', { min: min }) }
    : undefined;
};

export const isGreaterThanOrEqualTo = (minValue, msg, unformatter) => value => {
  const val =
    value !== '' ? Number(unformatter ? unformatter(value) : value ? stripDelimiters(value) : value) : undefined;
  const min =
    minValue !== ''
      ? Number(unformatter ? unformatter(minValue) : minValue ? stripDelimiters(minValue) : minValue)
      : undefined;

  return !isNaN(val) && val < min
    ? { type: 'error', msg: msg || i18n.t('validation.isGreaterThanOrEqualTo', { min: min }) }
    : undefined;
};

export const isLessThan = (maxValue, msg, unformatter) => minValue => {
  if (isNil(maxValue)) {
    return undefined;
  }
  const min = minValue !== '' ? Number(unformatter ? unformatter(minValue) : stripDelimiters(minValue)) : undefined;
  const max =
    maxValue !== ''
      ? Number(unformatter ? unformatter(maxValue) : maxValue ? stripDelimiters(maxValue) : maxValue)
      : undefined;

  return !isNaN(max) && !isNaN(min) && min >= max
    ? { type: 'error', msg: msg || i18n.t('validation.isLessThan', { max: max }) }
    : undefined;
};

export const isLessThanOrEqualTo = (maxValue, msg, unformatter) => minValue => {
  const min = minValue !== '' ? Number(unformatter ? unformatter(minValue) : stripDelimiters(minValue)) : undefined;
  const max =
    maxValue !== ''
      ? Number(unformatter ? unformatter(maxValue) : maxValue ? stripDelimiters(maxValue) : maxValue)
      : undefined;

  return !isNaN(max) && !isNaN(min) && min > max
    ? { type: 'error', msg: msg || i18n.t('validation.isLessThanOrEqualTo', { max: max }) }
    : undefined;
};

export const isGreaterThanOrEqual = ({ msg, formatter, unformatter }) => (maxValue, allValues, { name }) => {
  const [rangeName] = getRangeNameAndId(name);
  const max = maxValue !== '' ? Number(unformatter ? unformatter(maxValue) : stripDelimiters(maxValue)) : undefined;
  // Legacy || Final-Form
  const minValue =
    allValues[`${rangeName}.${RANGE(i18n.t).FROM.id}`] || getIn(allValues, `${rangeName}.${RANGE(i18n.t).FROM.id}`);
  const min =
    minValue !== ''
      ? Number(unformatter ? unformatter(minValue) : minValue ? stripDelimiters(minValue) : minValue)
      : undefined;
  const str = formatter ? formatter(min)[0] : min;

  return !isNaN(min) && !isNaN(max) && max < min
    ? { type: 'error', msg: msg || i18n.t('validation.isGreaterThanOrEqual', { str: str }) }
    : undefined;
};

export const isLessThanOrEqual = ({ msg, formatter, unformatter }) => (minValue, allValues, { name }) => {
  const [rangeName] = getRangeNameAndId(name);
  const min = Number(unformatter ? unformatter(minValue) : minValue ? stripDelimiters(minValue) : minValue);
  // Legacy || Final-Form
  const maxValue =
    allValues[`${rangeName}.${RANGE(i18n.t).TO.id}`] || getIn(allValues, `${rangeName}.${RANGE(i18n.t).TO.id}`);
  const max =
    maxValue !== ''
      ? Number(unformatter ? unformatter(maxValue) : maxValue ? stripDelimiters(maxValue) : maxValue)
      : undefined;
  const str = formatter ? formatter(max)[0] : max;

  return !isNaN(max) && min > max
    ? { type: 'error', msg: msg || i18n.t('validation.isLessThanOrEqual', { str: str }) }
    : undefined;
};

export const isEqualToOtherFieldLength = ({ msg, otherFieldPath, otherFieldName }) => (fieldValue, allValues) => {
  const otherFieldValue = get(allValues, otherFieldPath);
  const otherFieldValueLength = otherFieldValue?.length;
  const fieldValueLength = Number(fieldValue);

  return !isNaN(fieldValueLength) && otherFieldValueLength && fieldValueLength !== otherFieldValueLength
    ? { type: 'error', msg: msg || i18n.t('validation.isEqualToOtherFieldLength', { field: otherFieldName }) }
    : undefined;
};

export const isLessThanOrEqualToOtherFieldLength = ({ msg, otherFieldPath, otherFieldName }) => (
  fieldValue,
  allValues
) => {
  const otherFieldValueLength = get(allValues, otherFieldPath)?.length;
  const fieldValueLength = Number(fieldValue);

  return !isNaN(fieldValueLength) && otherFieldValueLength && fieldValueLength > otherFieldValueLength
    ? { type: 'error', msg: msg || i18n.t('validation.isLessThanOrEqualToOtherFieldLength', { field: otherFieldName }) }
    : undefined;
};

export const isEqualTo = (compr, msg, unformatter) => value => {
  let val = value;
  if (unformatter) {
    val = unformatter(val);
  }
  return val && parseFloat(val, base) !== parseFloat(compr, base)
    ? { type: 'error', msg: msg || i18n.t('validation.isEqualTo', { str: compr }) }
    : undefined;
};

export const minLengthCharacters = (min, msg) => value =>
  value && value.length < min
    ? { type: 'error', msg: msg || i18n.t('validation.minLengthCharacters', { min: min }) }
    : undefined;

export const maxLength = (max, msg) => value =>
  value && value.length > max ? { type: 'error', msg: msg || i18n.t('validation.maxLength', { max: max }) } : undefined;

export const maxLengthCharacters = (max, msg) => value => {
  if (isNil(max)) {
    return undefined;
  }
  return value && value.length > max
    ? { type: 'error', msg: msg || i18n.t('validation.maxLengthCharacters', { max: max }) }
    : undefined;
};

export const minLengthNotEmpty = (min, msg) => value =>
  isNil(value) || value?.length < min
    ? { type: 'error', msg: msg || i18n.t('validation.minLength', { min: min }) }
    : undefined;

export const minLength = (min, msg) => value =>
  value && value.length < min ? { type: 'error', msg: msg || i18n.t('validation.minLength', { min: min }) } : undefined;

export const startsWith = (str, msg) => value =>
  str && value && !_startsWith(value, str)
    ? { type: 'error', msg: msg || i18n.t('validation.startsWith', { str: str }) }
    : undefined;

export const areBothPresentOrEmpty = (other, label) => value => {
  const valueIsEmpty = isEmpty(value);
  const otherIsEmpty = isEmpty(other);

  return valueIsEmpty && !otherIsEmpty
    ? {
        type: 'error',
        msg: label ? i18n.t('validation.isRequired', { field: label }) : i18n.t('validation.required')
      }
    : undefined;
};

export const atLeastOneIsPresent = (other, msg) => value => {
  const valueIsEmpty = typeof value === 'boolean' ? !value : isEmpty(value);
  const otherIsEmpty = typeof other === 'boolean' ? !other : isEmpty(other);

  return valueIsEmpty && otherIsEmpty
    ? {
        type: 'error',
        msg: msg ? msg : i18n.t('validation.required')
      }
    : undefined;
};

// ==== DATE

export const invalidDateFormat = format => (value = '', allValues, { name } = {}) => {
  return !moment(value, [format, 'YYYY-MM-DD'], true).isValid()
    ? {
        type: 'error',
        msg: i18n.t('validation.invalidDateFormat')
      }
    : undefined;
};

/* Validates that the input date is after a certain time period into the past (ex. 31 days before today) */
export const startAfter = (value, unit, format, msg) => date => {
  const enteredDate = moment(date);
  const today = moment();
  const earliestAllowedDate = today.clone().subtract(value, unit);

  //An extra day needs to be subtracted if the time unit is anything other than "day" so that the day that falls exactly
  //on the time range is included. For example, if the time range is 10 years, then without this subtraction the date that
  //is exactly 10 years prior to today will be selectable on the date picker, but would fail this validator.
  if (!['day', 'days'].includes(unit?.toLowerCase())) {
    earliestAllowedDate.subtract(1, 'day');
  }

  return enteredDate.isBefore(earliestAllowedDate)
    ? {
        type: 'error',
        msg: msg
          ? msg
          : i18n.t('validation.startAfter', {
              str: earliestAllowedDate.format(format)
            })
      }
    : undefined;
};

/* Validates that the input date comes after the startDate */
export const isAfterDate = (startDate, format, msg) => date => {
  const startDateMoment = moment(startDate);
  const enteredDateMoment = moment(date);

  return !enteredDateMoment.isAfter(startDateMoment)
    ? {
        type: 'error',
        msg: msg
          ? msg
          : i18n.t('validation.isAfterDate', {
              date: startDateMoment.format(format)
            })
      }
    : undefined;
};

export const endBefore = (value, unit, format, msg) => date => {
  const today = moment();

  return moment(date).isAfter(today.clone().add(value, unit))
    ? {
        type: 'error',
        msg: msg
          ? msg
          : i18n.t('validation.endBefore', {
              str: today
                .clone()
                .add(value, unit)
                .format(format)
            })
      }
    : undefined;
};

export const maxDateRange = maxRange => (date, allValues, { name }) => {
  const [rangeName, rangeId] = getRangeNameAndId(name);

  const otherDate =
    allValues[name] ||
    getIn(
      allValues,
      `${rangeName}.${rangeId === DATE_RANGE(i18n.t).START.id ? DATE_RANGE(i18n.t).END.id : DATE_RANGE(i18n.t).END.id}`
    );

  return Math.abs(moment(date).diff(moment(otherDate), 'days')) > maxRange
    ? { type: 'error', msg: i18n.t('validation.maxDateRange', { count: maxRange }) }
    : undefined;
};

export const maxWildCards = (groupName, numberOfInstances, t) => (value, allValues, { name }) => {
  const groupId = name.split('.')[0];

  return value &&
    value.includes('*') &&
    Object.values(allValues[groupId]).filter(value => !isObject(value) && value.includes('*')).length >
      numberOfInstances
    ? {
        type: 'error',
        msg: t('filter.errorMessages.wildcardSearch', { title: groupName.substring(0, groupName.length - 1) })
      }
    : undefined;
};

export const maxFields = (groupName, numberOfInstances) => (value, allValues, { name }) => {
  const groupId = name.split('.')[0];

  return value &&
    Object.values(allValues[groupId]).filter(value => !isObject(value) && value).length > numberOfInstances
    ? {
        type: 'error',
        msg: i18n.t('validation.onlyOneField', { group: groupName })
      }
    : undefined;
};

/**
 * For the given filter group names, only a certain number of negations are allowed between them in total.
 * A negation is any text input that begins with "!".
 *
 * @param groupNames - Array of names of the groups whose total negations are to be limited
 * @param groupTitles - Array of titles of those groups, to be displayed in the error message
 * @param maxNumberOfNegations - Maximum number of negations allowed among the groups listed in groupNames
 * @param t - i18n translation object
 */
export const maxNegations = (groupNames, groupTitles, maxNumberOfNegations, t) => (value, allValues, { name }) => {
  //If input value doesn't start with "!", then no need to validate negation
  if (!value || (!isObject(value) && !value.trimStart().startsWith('!'))) return undefined;

  const groupIds = [];
  for (const groupName of groupNames) {
    const groupId = groupName.split('.')[0];
    groupIds.push(groupName);
  }

  let negationCount = 0;
  for (const groupId of groupIds) {
    const groupValues = allValues[groupId] || {};
    const groupNegationCount =
      Object.values(groupValues).filter(value => value && !isObject(value) && value?.trimStart().startsWith('!'))
        .length || 0;

    negationCount = negationCount + groupNegationCount;
  }

  return negationCount > maxNumberOfNegations
    ? {
        type: 'error',
        msg: t('validation.negationCountExceeded', {
          count: maxNumberOfNegations,
          groupListLabel: groupTitles.join(', ')
        })
      }
    : undefined;
};
