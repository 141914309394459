import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import intersectionWith from 'lodash/intersectionWith';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@jpmuitk/theme';

import { app } from 'config';

import { useField } from 'react-final-form';
import { useErrorProps, useInteractiveProps } from 'Components/Forms/Hooks';

import { Input } from '@jpmuitk/input';
import { List } from '@jpmuitk/list';
import { ComboBox } from '@jpmuitk/combo-box';
import { Checkbox } from '@jpmuitk/checkbox';
import { FormField } from '@jpmuitk/form-field';
import { FormLabel } from '@jpmuitk/form-label';
import { Dropdown as CapitalizedDropdown } from '@jpmuitk/dropdown';
import { ColumnLayout } from '@jpmuitk/column-layout';

import ConditionalWrapper from 'Components/ConditionalWrapper';

import { composeValidators } from 'utility/validation';

import { defaultDropdownStyle, listLabel } from './styles';

const Dropdown = withStyles(defaultDropdownStyle)(CapitalizedDropdown);
const ListLabel = withStyles(listLabel)(FormLabel);

const DropdownBuilder = props => {
  const {
    ariaLabel,
    defaultValue,
    fieldRest,
    helperText,
    helperTextPlacement,
    hide,
    id,
    initialSelectedItem,
    isDisabled,
    isRequired,
    isValidationDisabled,
    itemToString,
    label,
    noSuffix,
    onChange,
    onSelect,
    propsRest,
    readOnly,
    inputRef,
    source,
    type,
    validation,
    validateOnLoad,
    PopperProps,
    // Layout
    columnLayoutProps,
    useColumnLayout,
    labelPlacement
  } = useInteractiveProps(props);

  if (!readOnly && !source?.length > 0) {
    return null;
  }

  const [newSource, setSource] = useState(source);
  const { t } = useTranslation();

  const sourceLength = source?.length;

  React.useEffect(() => {
    // if - Used for adding a default value
    // else if - Used for updating source
    if (sourceLength && defaultValue && type === 'dropdown' && source.indexOf(defaultValue) < 0) {
      setSource([defaultValue, ...source]);
    } else if (sourceLength && type === 'dropdown') {
      setSource(source);
    }
  }, [source, defaultValue]);

  const [key, updateKey] = useState(0);
  const [highlight, setHighlight] = useState(false);
  const { input, meta } = useField(`${id}${noSuffix ? '' : 'Field'}`, {
    validate: composeValidators(validation({ isRequired })),
    parse: arrayValue => (!isEmpty(arrayValue) ? arrayValue : undefined)
  });
  const checkbox = fieldRest.checkboxName ? useField(`${fieldRest.checkboxName}${noSuffix ? '' : 'Field'}`) : {};
  const { type: errorType, msg } = useErrorProps({
    meta,
    validateOnLoad,
    isValidationDisabled,
    helperText
  });

  return hide ? null : (
    <ConditionalWrapper
      condition={useColumnLayout}
      wrapper={children => (
        <ColumnLayout item {...columnLayoutProps}>
          {children}
        </ColumnLayout>
      )}
    >
      {(type === 'dropdown' || !type) && !readOnly && !isDisabled ? (
        <FormField
          LabelProps={{
            NecessityIndicator: () => (isRequired ? <i>&nbsp;{t('necessity.required')}</i> : '')
          }}
          label={label({ t, type: 'dropdown' })}
          labelPlacement={labelPlacement}
          validationState={errorType}
          helperText={msg}
          readOnly={readOnly}
        >
          <Dropdown
            {...{
              source: newSource,
              onChange: (e, item) => {
                input.onChange(item);
                onChange && onChange(item);
              },
              PopperProps,
              selectedItem: input.value || defaultValue,
              itemToString
            }}
            {...propsRest}
            ButtonProps={{ 'data-test-id': id }}
            buttonRef={inputRef}
            key={input.value}
          />
        </FormField>
      ) : type === 'list' ? (
        <>
          <ListLabel id={`${id}-label`}>{label({ t, type: 'list' })}</ListLabel>
          <List
            virtualized
            key={key}
            data-test-id={id}
            width="100%"
            source={source}
            selectionVariant="multiple"
            displayedItemCount={app(t).list.rowCount}
            itemToString={itemToString}
            onChange={(e, item) => {
              checkbox.input?.onChange(source.length === item.length);
              input.onChange(item);
              onChange && onChange(item);
            }}
            selectedItem={intersectionWith(source, input.value, isEqual)}
            aria-labelledby={`${id}-label`}
          />
          {fieldRest.selectAll && (
            <Checkbox
              {...checkbox.input}
              checked={source.length === input.value.length}
              indeterminate={input.value?.length > 0 && source.length !== input.value?.length}
              label={fieldRest.selectAll}
              onChange={e => {
                checkbox.input?.onChange(e);
                if (source.length !== input.value.length) {
                  input.onChange(source);
                  onChange && onChange(source);
                } else {
                  input.onChange([]);
                  onChange && onChange([]);
                }
                onSelect && source.length !== input.value.length ? onSelect(source) : onSelect && onSelect([]);
                updateKey(key + 1);
              }}
            />
          )}
        </>
      ) : type === 'typeahead' ? (
        <FormField label={label({ t, type: 'list' })} style={{ maxWidth: 292 }}>
          <ComboBox
            multiSelect
            key={key}
            id={id}
            onChange={(e, item) => {
              input.onChange(item);
              onChange && onChange(item);
            }}
            source={source}
            selectedItem={intersectionWith(source, input.value, isEqual)}
            itemToString={itemToString}
          />
        </FormField>
      ) : (
        <FormField label={label()} labelPlacement={labelPlacement} readOnly={readOnly}>
          <Input
            {...input}
            value={
              input.value === ''
                ? null
                : isObject(input.value) && itemToString
                ? itemToString(input.value)
                : input.value
            }
            disabled={isDisabled}
            emptyReadOnlyMarker={''}
          />
        </FormField>
      )}
    </ConditionalWrapper>
  );
};

export default DropdownBuilder;
