import { defaultColDef, RENDERER_TYPE } from 'Components/DataGrid/utils';
import { viewDetailsColumns } from 'routes/TransactionDetail/Accordions/utils';

const cellHeight = 22; // cell height in px
const cellWidth = 27; // cell length in characters

const onCellFocused = ({ api: gridApi, column, columnApi, rowIndex }) => {
  const selectedCellWithinFocusableColumnRenderer =
    Number.isInteger(rowIndex) && gridApi.getFocusedCell()?.column?.colDef?.type?.includes(RENDERER_TYPE);

  if (selectedCellWithinFocusableColumnRenderer) {
    const cellToRefresh = {
      columns: [column],
      rowNodes: [gridApi.rowModel.rowsToDisplay[rowIndex]],
      force: true
    };

    gridApi.refreshCells(cellToRefresh);
  }
};

export const getRowHeight = (params, cols) => {
  const data = params.data;
  const columns = cols.map(column => column.field);
  let result = false;
  let height = 22;
  columns?.forEach(field => {
    result = Math.ceil(data[field]?.length / cellWidth);
    if (data && data[field]?.length > cellWidth && Number(result) * cellHeight >= height) {
      height = Number(result) * cellHeight;
    }
  });
  return height;
};

export const getConfigGridAndCols = ({ defaultColumnTypes, defaultColDefProps, gridProps } = {}) => {
  const filter = defaultColDefProps?.filter;
  const menuTabs = filter ? { menuTabs: ['filterMenuTab'] } : {};

  return {
    columnTypes: {
      ...defaultColumnTypes,
      renderer: {}
    },
    defaultColDef: {
      ...defaultColDef,
      ...defaultColDefProps,
      suppressMenu: filter ? !filter : true,
      ...menuTabs
    },
    ensureDomOrder: true,
    enableRangeSelection: false,
    suppressPaginationPanel: false,
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    suppressContextMenu: true,
    onCellFocused,
    ...gridProps
  };
};
