import 'core-js/features/array/flat';
import partition from 'lodash/partition';

import {
  CAPTURE_RESULTS,
  CAPTURE_HISTORY,
  CONCOURSE_ENTITY_MANAGER,
  CONCOURSE_IRA,
  CUSTOMER_CLASSIFICATION,
  SCAN,
  TRANSACTIONS,
  TRANSACTIONS_REQUIRING_ACTION,
  TRANSACTIONS_ASSIGNED_TO_ME,
  REMITTANCE_ADVICE_LIST,
  UPLOAD_REMITTANCE_ADVICES,
  USER_ADMIN,
  CREATE_USER,
  EDIT_USER,
  VIEW_USER,
  ENTITY_LIST,
  ENTITIES_REQUIRING_ACTIVATION,
  ENTITY_CREATE,
  ENTITY_UPLOAD,
  ONLINE_REPORTS,
  DOWNLOAD_CENTER,
  ENTITY_DETAILS,
  INBOX,
  INVOICE_IDENTIFICATION_PATTERNS,
  INVOICE_SEARCH,
  CREATE_ALERT,
  MANAGE,
  DISPLAYPRINTSETTINGS,
  REASON_CODES,
  SUPPORT_PAGE,
  DECISION_MANAGER_HISTORY,
  DECISIONS_REQUIRING_ACTION,
  STOP_GO,
  HCEOBEXCEPTIONS,
  UPLOAD_CONCOURSE_ENTITIES,
  UPLOAD_WORKFLOW_NOTES
} from 'Constants/routes';

const menuHandlers = {
  CaptureItemsRequiringAction: CAPTURE_RESULTS,
  CaptureHistory: CAPTURE_HISTORY,
  CaptureTransactions: SCAN,
  ConcourseEntityList: CONCOURSE_ENTITY_MANAGER,
  ConcourseActionItems: CONCOURSE_IRA,
  CreateNew: CREATE_ALERT,
  CustomerClassification: CUSTOMER_CLASSIFICATION,
  CreateUser: [USER_ADMIN, CREATE_USER].join(''),
  CreateEntity: ENTITY_CREATE,
  DecisionHistory: DECISION_MANAGER_HISTORY,
  DecisionsRequiringAction: DECISIONS_REQUIRING_ACTION,
  DisplayAndPrint: DISPLAYPRINTSETTINGS,
  DownloadCenter: DOWNLOAD_CENTER,
  EntityDetail: ENTITY_DETAILS,
  EditUser: [USER_ADMIN, EDIT_USER].join(''),
  EntityList: ENTITY_LIST,
  EntitiesRequiringActivation: ENTITIES_REQUIRING_ACTIVATION,
  HCEOBExceptions: HCEOBEXCEPTIONS,
  Inbox: INBOX,
  InvoiceIdentificationPatterns: INVOICE_IDENTIFICATION_PATTERNS,
  InvoiceSearch: INVOICE_SEARCH,
  Manage: MANAGE,
  OnlineReports: ONLINE_REPORTS,
  ReasonCode: REASON_CODES,
  RemittanceAdviceList: REMITTANCE_ADVICE_LIST,
  ReceivablesSupport: SUPPORT_PAGE,
  TransactionHistory: TRANSACTIONS,
  TransactionsRequiringAction: TRANSACTIONS_REQUIRING_ACTION,
  TransactionsAssignedToMe: TRANSACTIONS_ASSIGNED_TO_ME,
  UploadEntities: ENTITY_UPLOAD,
  ConcourseFileImport: UPLOAD_CONCOURSE_ENTITIES,
  UploadWorkFlowNotes: UPLOAD_WORKFLOW_NOTES,
  UploadRemittanceAdvices: UPLOAD_REMITTANCE_ADVICES,
  UploadStopGoFile: STOP_GO,
  ViewUser: [USER_ADMIN, VIEW_USER].join('')
};

export const flattenNavAndFindBy = (navigation, predicate) =>
  navigation
    .map(({ menuGroups }) => menuGroups)
    .flat()
    .map(({ menuItems }) => menuItems)
    .flat()
    .find(predicate) || {};

export const formatActionItems = ({ id, label, selected }, index) => ({
  id: `${index}`,
  index,
  label,
  href: menuHandlers[id],
  selected
});

export const formatMenu = (menuPaneIndex, menuIndex) => ({ id, label, selected }, index) => ({
  id: `${menuPaneIndex}${menuIndex}${index}`,
  index,
  label,
  href: menuHandlers[id],
  selected
});

export const formatReport = (menuPaneIndex, menuIndex) => ({ reportName, reportId, id, label, selected }, index) => ({
  reportName,
  reportId,
  id: `${menuPaneIndex}${menuIndex}${index}`,
  index,
  label,
  href: `${menuHandlers['OnlineReports']}/${reportId}`,
  selected
});

export const getActionItemsAndMenuGroups = menuPane => {
  const [actionItemGroupArray, menuGroups, actionItemGroup = actionItemGroupArray?.[0]] = partition(
    menuPane.menuGroups,
    {
      id: 'ActionItems'
    }
  );
  return actionItemGroup
    ? [{ hasActionItem: true, actionItems: actionItemGroup?.menuItems.map(formatActionItems) }, menuGroups]
    : [{}, menuGroups];
};
